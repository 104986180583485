const clientData = {
    client_entity: 78,
    attorney_firm: 'Jennifer C. Surmacz',
    attorney_name: 'Jennifer Surmacz',
    attorney_number: '(904) 256-9811',
    attorney_email: 'jsurmacz@posteritylegal.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_-_posterity_law+(720p).mp4',
    mainColor: '#2f2f2f',
    secondaryColor: '#d5b757',
}

export default clientData